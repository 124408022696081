import React, { useState, useEffect } from "react";
import { useStream } from "../../hooks/streamHooks";

function MuteUnmute({
  isOnAir,
  setIsOnAir,
  streamStatus,
  onlyIcon,
  onBtnClick,
  isConnected,
  setIsConnected,
}) {
  const { audioStream } = useStream();

  const { isInitialing, setIsInitialising, subscribe, unsubscribe } =
    audioStream;

  useEffect(() => {
    if (streamStatus === "ONLINE") {
      handleOnAir();
    }
    if (streamStatus === "OFFLINE") {
      handleOffAir();
    }
  }, [streamStatus]);

  function handleOnAir() {
    setIsOnAir(true);
  }

  function handleOffAir() {
    setIsOnAir(false);
  }

  const handleConnect = async () => {
    setIsInitialising(true);
    setIsConnected(true);
    await subscribe("audio");
    setIsInitialising(false);
  };

  const handleDisconnect = async () => {
    await unsubscribe("audio");
    setIsConnected(false);
  };

  return (
    <>
      {isOnAir &&
        (onlyIcon ? (
          <button
            onClick={() => {
              onBtnClick?.();
             !isConnected && handleConnect();
            }}
            style={{
              border: 0,
              outline: 0,
              color: "#FFFFFF",
              alignSelf: "center",
              background: "transparent",
            }}
          >
            <img
              width={32}
              height={32}
              src={
                isConnected ? "/assets/icon/unmute-icon-grey.svg" : "/assets/icon/mute-icon-grey.svg"
              }
              alt="mic icon"
            />
          </button>
        ) : (
          <>
            {isInitialing ? (
              <div className="spinner-border" role="status"></div>
            ) : !isConnected ? (
              <button
                className="rounded-pill px-4 py-2"
                style={{
                  border: 0,
                  outline: 0,
                  color: "#FFFFFF",
                  alignSelf: "center",
                  backgroundImage:
                    "linear-gradient(to bottom, #FF676B, #EE4044)",
                }}
                onClick={handleConnect}
              >
                Unmute to Listen
                <img
                  src="/assets/icon/mute-icon-grey.svg"
                  alt="search icon"
                  width={32}
                  height={32}
                  style={{ marginLeft: "10px" }}
                />
              </button>
            ) : (
              <button
                className="rounded-pill px-4 py-2"
                style={{
                  border: 0,
                  outline: 0,
                  color: "#FFFFFF",
                  alignSelf: "center",
                  backgroundImage:
                    "linear-gradient(to bottom, #FF676B, #EE4044)",
                }}
                onClick={handleDisconnect}
              >
                Mute
                <img
                  src="/assets/icon/unmute-icon-grey.svg"
                  alt="search icon"
                  width={32}
                  height={32}
                  style={{ marginLeft: "10px" }}
                />
              </button>
            )}
          </>
        ))}
    </>
  );
}
export default MuteUnmute;
